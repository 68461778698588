* {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
::file-selector-button {
  margin-right: 20px;
  margin-top: 1px;
  border: none;
  height: 36px;
  background: #d91e26;
  padding: 0 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
